@import '../common';

.messagesContainer {
  width: 100%;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  align-items: center;
}

.questionText {
  font-weight: 700;
  font-size: 27px;
  line-height: 34px;
  font-family: 'Montserrat';
  margin-top: 25px;
  padding: 0 16px;
  flex: 1;
}

.answerContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.anonymousContainer {
  display: flex;
  align-items: center;

  .answerAnonymouslyText {
    font-family: 'Montserrat';
  }
}

.btnContainer{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  width: 348px;
  height: 59px;
  border-radius: 10px;
  background: linear-gradient(0deg, #DADADA, #DADADA), #D9D9D9;
  pointer-events: none;

  &.active {
    background: linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%);
    pointer-events: all;
  }

  & .btnText {
    width: 132.46px;
    height: 23px;
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    cursor: pointer;
  }
}
.feedbackInfo {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 16px;
  background: linear-gradient(#E1F8FF, #E1F8FF) padding-box,
  linear-gradient(to right, #662FF9, #FE8475) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
}
